// HTML elements

*
    box-sizing: border-box
    position: relative

html, body
    font-size: 16px

body
    color: $color_dark
    font-family: arial
    text-decoration: none
    margin: auto
    font-size: $fontsize_reg
    line-height: $lineheight_reg
    word-wrap: break-word
    background-color: $color_light

a
    text-decoration: none
    color: $color_dark
    -webkit-transition: color $transitiontime
    transition: color $transitiontime
    &:hover
        color: $color_dark_secondary

h1,
h2,
h3,
h4,
h5,
h6
    margin: $spacing_m 0 $spacing_m 0
    padding: 0
    border: 0
    font-weight: normal
    line-height: $lineheight_xl
    font-size: $fontsize_xl
    font-weight: bold
    color: $color_primary

p
    display: block
    -webkit-margin-before: 0rem
    -webkit-margin-after: 0rem
    -webkit-margin-start: 0px
    -webkit-margin-end: 0px
    margin-top: 0px
    margin-bottom: 0px

ul
    list-style-type: square
    margin: 0
    padding: 0 0 0 1rem

li
input[type="submit"]
    -webkit-appearance: none
    appearance: none
    outline: none
    font-family: arial
    text-decoration: none
    font-size: $fontsize_reg
    line-height: $lineheight_reg
    word-wrap: break-word

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button
    -webkit-appearance: none

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-clear-button
    display: none

input[type="file"]::-webkit-inner-spin-button,
input[type="file"]::-webkit-outer-spin-button,
input[type="file"]::-webkit-clear-button
    display: none
    visibility: hidden

input,
textarea
    all: unset
    border: none

button,
input,
optgroup,
select,
textarea,
html input[type="button"],
input[type="text"],
input[type="reset"],
input[type="submit"],
button[disabled],
html input[disabled],
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="checkbox"],
input[type="radio"],
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="search"],
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="date"],
input[type="date"]::-webkit-search-cancel-button,
input[type="date"]::-webkit-search-decoration
    border: none
    background-image: none
    background-color: transparent
    -webkit-box-shadow: none
    -moz-box-shadow: none
    box-shadow: none
    background-color: grey

button
    font-family: inherit
    font-size: 100%
    line-height: 1.15
    margin: 0
    overflow: visible
    text-transform: none
    -webkit-appearance: button
    &::-moz-focus-inner
        border-style: none
        padding: 0
    &:-moz-focusring
        outline: 1px dotted ButtonText

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea
    -webkit-box-shadow: 0 0 0 30px $color_light inset !important





// Generic classes

.textbox
    display: block
    margin-bottom: $spacing_l
    width: 100%
    &:last-of-type
        margin-bottom: $spacing_l
    @include breakpoint(tablet)
        width: auto

.textbox--light
    color: $color_light

.text-bold
    font-weight: bold

.text-underline
    text-decoration: underline

.text-italic
    font-style: italic

.text-grey
    color: $color_grey_dark

.text-bold-extra
    font-weight: 1000

.text-color-primary
    color: $color_primary

.text-color-secondary
    color: $color_secondary

.text-color-light
    color: $color_light

.text-color-grey
    color: $color_grey_dark

.text-title
    font-size: $fontsize_xxl
    line-height: $lineheight_xxl
    font-weight: bold

.text-subtitle
    font-size: $fontsize_l
    line-height: $lineheight_l

.text-normal
    font-size: $fontsize_reg
    line-height: $lineheight_reg
    font-weight: normal

.text-justify
    text-align: justify

.link-color-light
    color: $color_light
    &:hover
        color: $color_light

.hidden
    display: none !important

.button
    width: 100%
    padding: $spacing_xs $spacing_m $spacing_xs $spacing_m
    border-radius: $border_radius
    text-align: center
    font-weight: bold
    cursor: pointer
    box-shadow: $shadow_med
    transition: all $transitiontime
    &:hover
        box-shadow: $shadow_med_none
    @include breakpoint(tablet)
        min-width: 8rem
        width: auto
        display: inline-block

.button--subtitle
    padding: $spacing_s $spacing_l
    @include breakpoint(tablet)
        min-width: 10rem

.button--margin
    margin: 0 $spacing_m 0 $spacing_m

.button-inactive
    background-color: $color_grey_light !important
    border: 0.2rem solid $color_grey_light
    color: $color_dark !important
    box-shadow: $shadow_med !important
    cursor: not-allowed
    &:hover
        background-color: $color_grey_light !important
        box-shadow: $shadow_med !important

.button-primary
    background-color: $color_primary
    border: 0.2rem solid $color_primary
    color: $color_light
    box-shadow: $shadow_med
    transition: all $transitiontime
    &:hover
        background-color: $color_primary_shade_1
        border-color: $color_primary_shade_1
        color: $color_light
        box-shadow: $shadow_med_none

.button-primary-light
    background-color: $color_light
    border: 0.2rem solid $color_primary
    color: $color_primary
    box-shadow: $shadow_med
    transition: all $transitiontime
    &:hover
        background-color: $color_light
        border-color: $color_primary_shade_1
        color: $color_primary_shade_1
        box-shadow: $shadow_med_none

.button-secondary
    background-color: $color_secondary
    border: 0.2rem solid $color_secondary
    color: $color_light
    box-shadow: $shadow_med
    transition: all $transitiontime
    &:hover
        background-color: $color_secondary_shade_1
        border-color: $color_secondary_shade_1
        color: $color_light
        box-shadow: $shadow_med_none

.button-secondary-light
    background-color: $color_light
    border: 0.2rem solid $color_secondary
    color: $color_secondary
    box-shadow: $shadow_med
    transition: all $transitiontime
    &:hover
        background-color: $color_light
        border-color: $color_secondary_shade_1
        color: $color_secondary_shade_1
        box-shadow: $shadow_med_none

.button-tertiary
    background-color: $color_grey_extralight
    border: 0.2rem solid $color_grey_light
    color: $color_dark
    box-shadow: none
    transition: all $transitiontime
    &:hover
        background-color: $color_grey_light
        box-shadow: none