@import 'config'
@import 'base'





// Header

.header
    width: 100%
    background-color: $color_light
    /** box-shadow: $shadow_med **/
    background-color: $color-primary
    position: sticky
    position: -webkit-sticky
    top: 0
    z-index: 90


.header-wrapper
    min-width: $windowsize_min
    width: $windowsize
    max-width: $windowsize_max
    padding: $spacing_s $spacing_l
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center
    @include breakpoint(tablet)
        flex-wrap: no-wrap
    @include breakpoint(desktop)
        padding: 0 $spacing_xl
        min-width: $windowsize_min_desktop
        width: $windowsize_wide_desktop
        max-width: $windowsize_wide_max_desktop

.header__logo
    width: 6rem
    height: 2rem
    margin: $spacing_s 0
    display: flex
    align-items: center
    /* fill: $color_primary */
    /* color: $color_light !important */
    background: url("media/images/logo_light.png")
    background-size: cover
    background-repeat: no-repeat
    background-position: center center


.header__menu
    width: 2rem
    height: 2rem
    display: flex
    flex-direction: column
    justify-content: space-around
    align-items: center
    align-content: center
    @include breakpoint(tablet)
        display: none

.header__menu__bar
    width: 100%
    height: 0.15rem
    background-color: $color_light
    transition: all $transitiontime

.header__menu__bar-1

.header__menu__bar-1--active
    +transform(rotateZ(45deg) translateY(0.65rem))

.header__menu__bar-2

.header__menu__bar-2--active
    display: none

.header__menu__bar-3

.header__menu__bar-3--active
    +transform(rotateZ(-45deg) translateY(-0.65rem))

.header__navbar
    display: none
    width: 100%
    @include breakpoint(tablet)
        display: flex
        align-items: stretch
        justify-content: flex-end
        width: auto
        flex-grow: 1

.header__navbar--active
        display: flex
        flex-direction: column

.header__navbar__element
    width: 100%
    padding: $spacing_xs 0 $spacing_xs 0
    margin: 0
    cursor: pointer
    display: flex
    align-items: center
    @include breakpoint(tablet)
        width: auto
        margin: 0 $spacing_m
        align-items: center
        &:last-of-type
            margin-right: 0

.header__navbar__link
    width: 100%
    height: 100%
    color: $color_light
    display: flex
    justify-content: flex-start
    align-items: center
    transition: all $transitiontime
    &:hover
        color: $color_primary_tint_3
    @include breakpoint(tablet)
        justify-content: center





// Footer

.footer
    width: 100%
    background: url("media/patterns/flower_1.png")
    background-repeat: repeat
    background-position: center center
    background-color: $color_primary
    color: $color_light
    min-height: 5rem
    z-index: 80
    display: flex
    justify-content: center
    align-items: flex-start

.footer-wrapper
    min-width: $windowsize_min
    width: $windowsize
    max-width: $windowsize_max
    padding: $spacing_xl $spacing_l
    display: flex
    justify-content: space-between
    align-items: flex-start
    flex-wrap: wrap
    @include breakpoint(desktop)
        padding: $spacing_xl $spacing_xl
        min-width: $windowsize_min_desktop
        width: $windowsize_desktop
        max-width: $windowsize_max_desktop

.footer__column
    width: 100%
    padding: $spacing_xl 0 $spacing_xl 0
    @include breakpoint(tablet)
        width: calc(50% - #{$spacing_xl})
    @include breakpoint(desktop)
        width: calc(33.333% - #{$spacing_xl})
        padding: $spacing_l 0 $spacing_l 0

.footer__column__title
    width: 100%
    margin-top: $spacing_xl
    &:first-of-type
        margin-top: 0

.footer__column__element
    width: 100%
    margin-bottom: $spacing_s
    &:last-of-type
        margin-bottom: 0

.footer-social-media-container
    display: flex
    height: $lineheight_reg

.footer-social-media-logo
    width: $lineheight_xl
    height: $lineheight_xl
    margin: 0 $spacing_xs 0 $spacing_xs
    fill: $color_light
    transition: fill 500ms
    &:hover
        fill: url(#gradient-horizontal) $color_light






// Semi-generic classes

.content-container
    width: 100%
    position: relative

.content-container--background-grey
    background-color: $color_grey_extralight

.content-wrapper
    min-width: $windowsize_min
    width: 100%
    padding: 0 $spacing_l
    @include breakpoint(desktop)
        min-width: $windowsize_min_desktop
        width: 100%
        padding: 0 $spacing_xl

.content-wrapper--center
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.content-wrapper--event
    padding: 0

.background-flowers
    background: url("media/patterns/flower_1.png")
    background-repeat: repeat
    background-position: center center
    background-color: $color_primary

.input
    border: 1px solid $color_dark
    border-radius: $border_radius
    display: flex
    justify-content: flex-start
    margin-bottom: $spacing_l
    &:last-of-type
        margin-bottom: $spacing_l

.input--disabled
    border-color: $color_grey
    color: $color_grey





// Popup classes

.overlay
    width: 100%
    height: 100%
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: $color_dark
    background-color: rgba($color_dark_r, $color_dark_g, $color_dark_b, 0.9)
    display: flex
    justify-content: center
    align-items: center
    z-index: 99

.overlay__popup
    min-width: $windowsize_min
    width: 90vw
    max-width: calc(100vw - #{$spacing_xl})
    max-height: 90vh
    @include breakpoint(laptop)
        min-width: $windowsize_min_desktop
        width: 90vw
        max-width: 1000px

.overlay__loading
    color: $color_light





// Form classes
    
.form-container
    background-color: $color_light
    border-radius: $border_radius

.form__header
    width: 100%
    border-radius: $border_radius $border_radius 0 0
    background-color: $color_primary
    color: $color_light
    display: flex
    justify-content: space-between
    align-items: center

.form__header__title
    flex-grow: 1
    margin: $spacing_s $spacing_s
    font-size: $fontsize_l
    line-height: $lineheight_l
    font-weight: bold

.form__header__close
    width: $lineheight_l
    height: $lineheight_l
    flex-grow: 0
    margin: $spacing_s $spacing_s
    fill: $color_light
    cursor: pointer

.form__content
    width: 100%
    max-height: 80vh
    padding: $spacing_m $spacing_m
    border-radius: 0 0 $border_radius $border_radius
    overflow-y: auto

.form__content--no-padding
    padding: 0

.form__content__element
    margin-top: $spacing_l
    &:first-of-type
        margin-top: 0

.form__content__element--double-columns
    display: grid
    grid-template-columns: 1fr
    column-gap: $spacing_l
    row-gap: $spacing_l
    justify-items: stretch
    align-items: center
    justify-content: center
    align-content: start
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr

.form__content__input

.form__content__optionbox
    width: 100%
    display: grid
    grid-template-columns: 1fr
    column-gap: $spacing_s
    row-gap: $spacing_s
    justify-items: stretch
    align-items: center
    justify-content: center
    align-content: start
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr

.form__content__optionbox--four
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr
    @include breakpoint(laptop)
        grid-template-columns: 1fr 1fr 1fr 1fr

.form__content__optionbox__option
    padding: $spacing_s
    display: flex
    flex-direction: column
    align-items: center
    border-radius: $border_radius
    cursor: pointer
    &:hover
        background-color: $color_grey_extralight

.form__content__optionbox__option--selected
    background-color: $color_grey_extralight

.form__content__optionbox__option__illustration
    width: 10rem
    height: 10rem
    border-radius: $border_radius

.form__content__optionbox__option__label
    width: auto
    margin-top: $spacing_s
    font-weight: bold
    color: $color_primary

.form__content__buttonbox
    width: 100%
    display: flex
    flex-wrap: nowrap
    justify-content: space-between
    align-items: stretch
    flex-direction: column
    @include breakpoint(tablet)
        flex-wrap: nowrap
        justify-content: space-between
        align-items: stretch
        flex-direction: row-reverse

.form__content__buttonbox__button
    width: 100%
    margin-top: $spacing_m
    &:first-of-type
        margin-top: 0
    @include breakpoint(tablet)
        width: calc(50% - #{$spacing_s})
        margin-top: 0






// Input classes

.form__input-container
    width: 100%
    padding: 0
    display: flex
    flex-direction: column
    justify-content: flex-start

.form__input-container--half
    width: calc(50% - #{$spacing_l})
        
.form__input__headline
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    padding-bottom: $spacing_xs

.form__input__headline__label
    flex-grow: 1

.form__input__headline__infobox
    display: flex
    justify-content: flex-end
    align-items: center

.form__input__headline__infobox__icon
    width: 1rem
    height: 1rem
    fill: $color_primary
    border-radius: 0.5rem
    margin-left: $spacing_s
    cursor: pointer

.form__input__headline__infobox__icon--required
    fill: $color_secondary

.form__input__switch
    display: flex
    justify-content: space-between
    align-items: center
    flexwrap: wrap
    background-color: $color_grey_light
    padding: $spacing_xs $spacing_s
    border-radius: $border_radius
        
.form__input__switch__button
    width: calc(50% - #{$spacing_xs})
    padding: $spacing_xs $spacing_m
    border-radius: $border_radius
    text-align: center
    cursor: pointer
    transition: all $transitiontime
    &:hover
        background-color: $color_grey_extralight
        
.form__input__switch__button--selected
    background-color: $color_light
    color: $color_primary
    font-weight: bold
    &:hover
        background-color: $color_light
        color: $color_primary


.form__input__text,
.form__input__textbox,
.form__input__number,
.form__input__price,
.form__input__date
    width: 100%
    border: 1px solid $color_grey
    cursor: pointer
    border-radius: $border_radius

.form__input__price,
.form__input__date
    display: flex
    justify-content: flex-start
    align-items: stretch

.form__input__text__input,
.form__input__textbox__input,
.form__input__number__input,
.form__input__price__input
    width: 100%
    box-sizing: border-box
    padding: $spacing_s
    border-radius: $border_radius

.form__input__dropdown
    width: 100%
    border: 1px solid $color_grey
    cursor: pointer
    border-radius: $border_radius

.form__input__dropdown-multi-wrapper
    width: 100%
    display: flex
    justify-content: flex-start
    align-items: center
    flex-wrap: wrap

.form__input__text--active,
.form__input__number--active,
.form__input__price--active,
.form__input__date--active,
.form__input__dropdown--active
    border-color: $color_primary

.form__input__dropdown-multi__value
    margin: $spacing_xs
    padding: $spacing_xs
    border-radius: $border_radius
    background-color: $color_primary
    color: $color_light

.form__input__dropdown__input
    width: 100%
    box-sizing: border-box
    padding: $spacing_s

.form__input__dropdown__input--multi
    width: auto
    flex-grow: 1

.form__input__dropdown-container
    max-height: 16rem
    width: 100%
    overflow-y: auto
    display: none

.form__input__dropdown-container--active
    display: flex
    flex-direction: column

.form__input__dropdown__element
    width: 100%
    padding: $spacing_xs
    border-top: 1px solid $color_grey_light
    &:hover
        background-color: $color_primary
        color: $color_light
    &:last-of-type
        border-bottom-right-radius: $border_radius
        border-bottom-left-radius: $border_radius

.form__input__dropdown__element--selected
    background-color: $color_primary
    color: $color_light

.form__input__textbox
    width: 100%
    border: 1px solid $color_grey
    cursor: pointer
    border-radius: $border_radius

.form__input__textbox__input
    width: 100%
    height: 100%
    min-height: calc(4 * #{$lineheight_reg})
    box-sizing: border-box
    padding: $spacing_s
    border-radius: $border_radius

.form__input__textbox--active
    border-color: $color_primary
        

.form__input__date
    width: 100%
    border: 1px solid $color_grey
    cursor: pointer
    border-radius: $border_radius

.form__input__date--active
    border-color: $color_primary

.form__input__date__input
    width: 100%
    box-sizing: border-box
    padding: $spacing_s
        

.form__input__time
    width: 100%
    border: 1px solid $color_grey
    cursor: pointer
    border-radius: $border_radius

.form__input__time--active
    border-color: $color_primary

.form__input__time__input
    width: 100%
    box-sizing: border-box
    padding: $spacing_s


.form__input__checkbox
    width: 100%
    cursor: pointer
    display: flex
    justify-content: flex-start
    align-items: center

.form__input__checkbox__checkbox
    width: $lineheight_l
    height: $lineheight_l
    border: 0.15rem solid $color_grey
    border-radius: $border_radius
    margin-right: $spacing_s
    display: flex
    justify-content: center
    align-items: center
    transition: all $transitiontime

.form__input__checkbox__checkbox--active
    border-color: $color_primary

.form__input__checkbox__checkbox-inner
    width: calc(100% - 0.3rem)
    height: calc(100% - 0.3rem)
    background-color: transparent
    border-radius: $border_radius_s
    transition: all $transitiontime

.form__input__checkbox__checkbox--active .form__input__checkbox__checkbox-inner
    background-color: $color_primary
    
.form__input__checkbox:hover .form__input__checkbox__checkbox-inner
    background-color: $color_primary_tint_3

.form__input__checkbox__label
    min-width: 6rem
    flex-grow: 1





// Loading

.loading-container
    width: 100%
    height: 100%
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: $color_dark
    background-color: rgba($color_dark_r, $color_dark_g, $color_dark_b, 0.9)
    color: $color_light
    font-size: 2rem
    line-height: 2rem
    display: flex
    justify-content: center
    align-items: center
    z-index: 99





// Banner

.banner
    min-width: $windowsize_min
    width: 100%
    padding: $spacing_m $spacing_l
    position: fixed
    top: 0
    left: 0
    right: 0
    background-color: $color_secondary
    z-index: 100
    display: grid
    grid-template-columns: 1fr auto
    column-gap: $spacing_l
    justify-items: stretch
    align-items: start
    justify-content: stretch
    align-content: stretch
    @include breakpoint(desktop)
        min-width: $windowsize_min_desktop
        width: 100%
        padding: $spacing_m $spacing_xl

.banner__notification
    height: 5rem

.banner__close
    width: $spacing_l
    height: $spacing_l
    fill: $color_light
    cursor: pointer





// Home

.hero
    background-color: $color_primary

.hero-wrapper
    min-height: calc(80vh - 2rem - #{$spacing_s} - #{$spacing_s})
    display: flex
    justify-content: center
    justify-items: stretch
    display: grid
    grid-template-columns: 1fr
    column-gap: $spacing_m
    justify-items: stretch
    align-items: stretch
    justify-content: stretch
    align-content: stretch
    @include breakpoint(laptop)
        grid-template-columns: 1fr 1fr

.hero__illustration
    width: 100%
    height: 100%
    display: none
    @include breakpoint(laptop)
        display: flex
        justify-content: center
        align-items: center

.hero__illustration__screen
    width: 100%
    height: auto
    position: relative

.hero__illustration__screen-outer
    width: 100%
    height: 100%
    z-index: 2

.hero__illustration__screen-inner
    width: auto
    height: auto
    overflow: hidden
    position: relative
    position: absolute
    top: 11%
    bottom: 12%
    left: 12%
    right: 12%
    z-index: 1
    background-color: $color_light

.hero__illustration__screen__video
    object-fit: contain
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 100%
    height: 100%

.hero__content
    width: 100%
    height: 100%
    padding: $spacing_xl 0
    display: grid
    grid-template-columns: 1fr
    row-gap: $spacing_m
    justify-items: flex-start
    align-items: center
    justify-content: stretch
    align-content: center

.hero__content__title
    width: auto

.hero__content__subtitle
    width: auto

.hero__content__actionbutton
    display: block

.home__section
    width: 100%
    max-width: $content_width_max
    margin-left: auto
    margin-right: auto
    padding-top: $spacing_xxxl
    padding-bottom: $spacing_xxxl
    &:first-of-type
        padding-top: $spacing_xxxxl
    &:last-of-type
        padding-bottom: $spacing_xxxxl

.home__section__title
    width: 100%
    text-align: left
    margin-bottom: $spacing_xl
    @include breakpoint(tablet)
        text-align: center

.home__section__contentbox
    width: 100%

.home__section__buttonbox
    width: auto
    height: auto
    display: grid
    grid-template-columns: 1fr
    column-gap: $spacing_l
    row-gap: $spacing_l
    justify-items: center
    align-items: center
    justify-content: center
    align-content: center
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr

.home__features-container
    width: 100%
    height: 100%
    padding: $spacing_l $spacing_xl $spacing_xl $spacing_xl
    border-radius: $border_radius_l
    background-color: $color_grey_light
    box-shadow: $shadow_med
    position: relative

.home__features__navbar
    width: 100%
    display: grid
    grid-template-columns: 1fr
    column-gap: 0
    row-gap: $spacing_s
    justify-items: stretch
    align-items: center
    justify-content: center
    align-content: start
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr 1fr
        border-bottom: 0.1rem solid $color_grey

.home__features__navbar__element
    padding: $spacing_s $spacing_s
    cursor: pointer
    font-weight: bold
    color: $color_primary
    text-align: center
    border-radius: $border_radius_l
    transition: all $transitiontime
    &:hover
        background-color: $color_primary
        color: $color_light
    @include breakpoint(tablet)
        border-radius: 0
        border-bottom: 0.3rem solid transparent
        &:hover
            background-color: transparent
            color: $color_primary
            border-bottom: 0.3rem solid $color_primary_tint_3

.home__features__navbar__element--selected
    background-color: $color_primary
    color: $color_light
    &:hover
        background-color: $color_primary
        color: $color_light
    @include breakpoint(tablet)
        background-color: transparent
        color: $color_primary
        border-bottom: 0.3rem solid $color_primary
        &:hover
            background-color: transparent
            color: $color_primary
            border-bottom: 0.3rem solid $color_primary

.home__features__content-container
    width: 100%
    display: none

.home__features__content-container--selected
    margin-top: $spacing_l
    display: grid
    grid-template-columns: 1fr
    row-gap: $spacing_l
    justify-items: stretch
    align-items: center
    justify-content: center
    align-content: start
    grid-auto-flow: row
    @include breakpoint(tablet)
        column-gap: $spacing_xl
        grid-template-columns: 2fr 1fr

.home__features__content__screen
    height: auto
    display: flex
    justify-content: center

.home__features__content__screen__inner
    width: 100%
    height: auto
    box-shadow: $shadow_med

.home__features__content__description
    display: grid
    grid-template-columns: 1fr
    row-gap: $spacing_m
    grid-auto-flow: row

.home__benefitlist
    width: 100%
    display: grid
    grid-template-columns: 1fr
    column-gap: $spacing_l
    row-gap: $spacing_l
    justify-items: stretch
    align-items: center
    justify-content: center
    align-content: start
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr 1fr

.home__benefit
    width: 100%
    height: 100%
    padding: $spacing_xl $spacing_xl
    border-radius: $border_radius_l
    background-color: $color_grey_light
    box-shadow: $shadow_med
    position: relative
    @include breakpoint(tablet)
        padding: $spacing_xl $spacing_l
    @include breakpoint(laptop)
        padding: $spacing_l $spacing_xl $spacing_xl $spacing_xl

.home__benefit__label
    width: 100%
    color: $color_primary
    margin-top: $spacing_m

.home__benefit__description
    width: 100%
    margin-top: $spacing_s

.home__benefit__illustration
    width: 100%
    height: auto
    min-height: 6rem
    display: flex
    justify-content: center

.home__benefit__illustration__inner
    width: 100%
    height: auto

.home__working
    width: 100%
    display: grid
    grid-template-columns: 1fr
    column-gap: 0
    row-gap: $spacing_s
    justify-items: stretch
    align-items: stretch
    justify-content: stretch
    align-content: stretch
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr $spacing_l 1fr $spacing_l 1fr
        column-gap: $spacing_m
        row-gap: $spacing_xl

.home__working__step
    width: 100%
    height: 100%
    padding: $spacing_xl
    border-radius: $border_radius_l
    background-color: $color_grey_light
    box-shadow: $shadow_med
    background-color: $color_grey_light
    @include breakpoint(tablet)
        padding: $spacing_xl $spacing_l
    @include breakpoint(laptop)
        padding: $spacing_xl

.home__working__step__illustration-wrapper
    width: 100%
    height: auto
    display: flex
    justify-content: center
    align-items: center

.home__working__step__illustration
    width: 100%
    max-width: $spacing_xxxxl
    height: auto
    border-radius: 50%
    @include breakpoint(tablet)
        max-width: 100%
        height: auto
    @include breakpoint(laptop)
        max-width: $spacing_xxxl
        height: auto

.home__working__step__illustration__shadow
    width: 100%
    max-width: $spacing_xxxxl
    height: 100%
    border-radius: 50%
    box-shadow: $shadow_med inset
    position: absolute
    @include breakpoint(tablet)
        max-width: 100%
    @include breakpoint(laptop)
        max-width: $spacing_xxxl

.home__working__step__label
    width: 100%
    margin-top: $spacing_l

.home__working__step__description
    width: 100%
    margin-top: $spacing_s

.home__working__separator
    display: flex
    justify-content: center
    align-items: center

.home__working__separator__arrow
    width: $spacing_m
    height: $spacing_xl
    display: flex
    justify-content: center
    align-items: center
    fill: $color_primary
    transform: rotate(90deg)
    @include breakpoint(tablet)
        transform: rotate(0)

.home__testimonials-container
    width: 100%
    display: grid
    grid-template-columns: 3rem 1fr 3rem
    justify-items: stretch
    align-items: center
    justify-content: center
    align-content: center
    grid-auto-flow: row

.home__testimonials__button
    width: 100%
    height: $spacing_xl
    display: flex
    justify-content: center
    align-items: center
    fill: $color_primary_tint_3
    cursor: pointer
    transition: all $transitiontime
    &:hover
        fill: $color_primary

.home__testimonials__button__previous
    transform: rotate(180deg)

.home__testimonials__button__next
    transform: rotate(0deg)

.home__testimonials__testimonial
    margin: 0 $spacing_l
    padding: $spacing_l $spacing_l
    display: grid
    grid-template-columns: 1fr
    column-gap: $spacing_l
    row-gap: $spacing_l
    justify-items: stretch
    align-items: stretch
    justify-content: center
    align-content: center
    grid-auto-flow: row
    box-shadow: $shadow_med
    border-radius: $border_radius_l
    background-color: $color_grey_extralight
    transition: all $transitiontime
    @include breakpoint(tablet)
        margin: 0 $spacing_l
        padding: $spacing_xl $spacing_xl
        grid-template-columns: 1fr 2fr

.home__testimonials__testimonial--hidden
    display: none

.home__testimonials__testimonial__picture-container
    display: flex
    flex-direction: column

.home__testimonials__testimonial__picture
    width: 8rem
    height: 8rem
    border-radius: 50%
    box-shadow: $shadow_med inset

.home__testimonials__testimonial__picture--jamar
    background: url("media/images/home/testimonials_jamar.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.home__testimonials__testimonial__picture--oskar
    background: url("media/images/home/testimonials_oskar.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.home__testimonials__testimonial__picture--janice
    background: url("media/images/home/testimonials_janice.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.home__testimonials__testimonial__name
    width: 100%
    margin-top: $spacing_m
    color: $color_grey_dark

.home__testimonials__testimonial__title
    width: 100%
    margin-top: $spacing_xs

.home__testimonials__testimonial__quote-container

.home__testimonials__testimonial__icon
    width: $spacing_l
    height: $spacing_l
    background: url("media/images/home/testimonials_quote.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.home__testimonials__testimonial__quote
    margin-top: $spacing_m
    display: grid
    grid-template-columns: 1fr
    row-gap: $spacing_s
    grid-auto-flow: row
    font-style: italic

.home__references-container
    min-width: $windowsize_min
    width: 100%
    padding: $spacing_l $spacing_l
    background-color: $color_primary
    @include breakpoint(desktop)
        min-width: $windowsize_min_desktop
        width: 100%
        padding: $spacing_l $spacing_xl

.home__references-wrapper
    width: 100%
    display: grid
    grid-template-columns: 10rem
    column-gap: $spacing_xxl
    row-gap: $spacing_l
    justify-items: center
    align-items: center
    justify-content: center
    align-content: center
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 8rem 8rem
    @include breakpoint(laptop)
        grid-auto-flow: column
        grid-auto-columns: 8rem

.home__references__reference
    width: 100%
    height: auto

.contact__title
    padding-top: $spacing_xxl
    text-align: center

.contact__section
    width: 100%
    max-width: $content_width_max
    margin-left: auto
    margin-right: auto
    padding-top: $spacing_xl
    padding-bottom: $spacing_xl
    &:first-of-type
        padding-top: $spacing_xl
    &:last-of-type
        padding-bottom: $spacing_xxl
    @include breakpoint(laptop)
        max-width: 50rem



.home__working__illustration
    display: flex
    align-items: center

.home__working__illustration--left
    justify-content: flex-end

.home__working__illustration--right
    display: flex
    justify-content: flex-start

.home__working__illustration-inner
    width: 20rem
    height: 20rem
    border-radius: 50%
    box-shadow: $shadow_med inset

.home__working__illustration-inner--guest
    background: url("media/images/home_guests_1.jpeg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.home__working__illustration-inner--hotel
    background: url("media/images/home_hotels_1.jpeg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.home__working__textbox
    display: inline-block

.home__working__textbox--left

.home__working__textbox--right

.home__working__textbox__content
    width: 100%
    margin-top: $spacing_l

.home__working__textbox__paragraph
    margin-top: $spacing_s
    &:first-of-type
        margin-top: 0

.home__working__icon
    background-color: grey





// Getstarted
    
.form__content__optionbox__option__illustration--business
    background: url("media/images/home_mode_business.jpeg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--leisure
    background: url("media/images/home_mode_leisure.jpeg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--business_essentials
    background: url("media/images/home_cat_business_essentials.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--electronics
    background: url("media/images/home_cat_electronics.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--personal_care
    background: url("media/images/home_cat_personal_care.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--hair_care
    background: url("media/images/home_cat_hair_care.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--clothing
    background: url("media/images/home_cat_clothing.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--fitness
    background: url("media/images/home_cat_fitness.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--entertainment
    background: url("media/images/home_cat_entertainment.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--other
    background: url("media/images/home_cat_other.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.form__content__optionbox__option__illustration--extension_monitor
    background: url("media/images/home_item_extension_monitor.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.form__content__optionbox__option__illustration--mouse
    background: url("media/images/home_item_mouse.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.form__content__optionbox__option__illustration--keyboard
    background: url("media/images/home_item_keyboard.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.form__content__optionbox__option__illustration--projector
    background: url("media/images/home_item_projector.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.form__content__optionbox__option__illustration--printer
    background: url("media/images/home_item_printer.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.form__content__optionbox__option__illustration--charger
    background: url("media/images/home_item_charger.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.form__content__optionbox__option__illustration--headphones
    background: url("media/images/home_item_headphones.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--international_adapters
    background: url("media/images/home_item_international_adapters.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.form__content__optionbox__option__illustration--portable_wifi
    background: url("media/images/home_item_portable_wifi.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--skin_care
    background: url("media/images/home_item_skin_care.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.form__content__optionbox__option__illustration--toiletries
    background: url("media/images/home_item_toiletries.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.form__content__optionbox__option__illustration--premium_hairdryer
    background: url("media/images/home_item_premium_hairdryer.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center

.form__content__optionbox__option__illustration--straightener
    background: url("media/images/home_item_straightener.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--curler
    background: url("media/images/home_item_curler.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--dresses
    background: url("media/images/home_item_dresses.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--accessories
    background: url("media/images/home_item_accessories.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--handbags
    background: url("media/images/home_item_handbags.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--suits
    background: url("media/images/home_item_suits.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--yoga_mat
    background: url("media/images/home_item_yoga_mat.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--weights
    background: url("media/images/home_item_weights.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    
.form__content__optionbox__option__illustration--gaming_consoles
    background: url("media/images/home_item_gaming_consoles.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: center center





// Hotel

.hotel-header-container
    width: 100%
    margin-top: $spacing_l

.hotel-header-wrapper
    display: flex
    flex-wrap: wrap
    flex-direction: column-reverse
    justify-content: flex-start
    align-items: flex-start
    align-content: flex-start
    @include breakpoint(tablet)
        flex-wrap: nowrap
        flex-direction: row
        justify-content: space-between
        align-items: center
        align-content: center

.hotel-header__logo
    width: 12rem
    height: 4rem
    flex-grow: 0
    display: flex
    justify-content: center
    align-items: center
    @include breakpoint(tablet)
        height: 6rem

.hotel-header__logo-inner
    width: 100%
    object-fit: contain
    object-position: 50% 50%
    max-height: 100%

.hotel-header__detailbox
    min-width: 12rem
    flex-grow: 1
    margin-top: $spacing_s
    @include breakpoint(tablet)
        margin-top: 0

.hotel-header__detailbox__element
    margin-top: $spacing_xxs
    color: $color_grey_dark
    transition: all $transitiontime
    &:first-of-type
        margin-top: 0

.hotel-header__detailbox__element a
    color: $color_grey_dark
    &:hover
        color: $color_dark





// Shop

.shop-container,
.rentallist-container
    width: 100%
    margin-top: $spacing_l
    margin-bottom: $spacing_l
    border-top: $border-subtle

.shop-wrapper,
.rentallist-wrapper
    width: 100%
    display: flex
    justify-content: center
    flex-wrap: wrap
    align-items: stretch
    align-content: flex-start
    @include breakpoint(tablet)
        justify-content: flex-start
        flex-wrap: nowrap
        align-items: stretch
        align-content: flex-start

.shop__categories,
.rentallist__categories
    width: 100%
    padding: $spacing_l 0 $spacing_l 0
    @include breakpoint(tablet)
        width: auto
        padding: $spacing_l $spacing_m $spacing_l 0
        border-right: $border-subtle

.shop__categories__section,
.rentallist__categories__section
    width: 100%
    margin-top: $spacing_m
    &:first-of-type
        margin-top: 0
    @include breakpoint(tablet)
        margin-top: $spacing_l

.shop__categories__element,
.rentallist__categories__element
    min-width: 12rem
    padding: $spacing_xs $spacing_s
    border-radius: $border_radius
    margin-top: $spacing_xs
    cursor: pointer
    background-color: $color_grey_light
    transition: all $transitiontime
    &:first-of-type
        margin-top: 0
    &:hover
        background-color: $color_grey
    @include breakpoint(tablet)
        margin-top: $spacing_xs

.shop__categories__element--selected,
.rentallist__categories__element--selected
    color: $color_light
    background-color: $color_primary
    transition: all $transitiontime
    &:hover
        background-color: $color_primary_shade_1

.shop__catalogue
    padding: 0 0 $spacing_l 0
    flex-grow: 1
    display: grid
    grid-template-columns: 1fr
    column-gap: $spacing_m
    row-gap: $spacing_l
    justify-items: stretch
    align-items: stretch
    justify-content: center
    align-content: start
    grid-auto-flow: row
    @include breakpoint(phablet)
        grid-template-columns: 1fr 1fr
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr
        padding: $spacing_l 0 $spacing_l $spacing_m
        column-gap: $spacing_m
        row-gap: $spacing_m
    @include breakpoint(laptop)
        grid-template-columns: 1fr 1fr 1fr
    @include breakpoint(desktop)
        grid-template-columns: 1fr 1fr 1fr 1fr
    @include breakpoint(largedesktop)
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr

.shop__catalogue__element
    padding: $spacing_m
    border: 0.2rem solid $color_light
    border-radius: $border_radius
    transition: all $transitiontime
    &:hover
        box-shadow: $shadow_med
    @include breakpoint(tablet)
        padding: $spacing_m

.shop__catalogue__element--selected
    border-radius: $border_radius
    border-color: $color_primary

.shop__catalogue__element--selected-wishlist
    border-radius: $border_radius
    border-color: $color_secondary

.shop__catalogue__element__picture
    width: 100%
    min-height: 10rem
    max-height: 12rem
    border-radius: $border_radius
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden
    @include breakpoint(tablet)
        max-height: 20rem

.shop__catalogue__element__picture-unavailable
    position: absolute
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.5)
    color: $color_light
    display: flex
    justify-content: center
    align-items: center

.shop__catalogue__element__picture-inner
    width: 100%
    padding: $spacing_s
    height: 12rem
    object-fit: contain
    object-position: 50% 50%
    overflow: hidden

.shop__catalogue__element__label
    width: 100%
    margin-top: $spacing_xs

.shop__catalogue__element__details
    width: 100%
    color: $color_grey_dark

.shop__catalogue__element__actionbox
    width: 100%
    margin-top: $spacing_xs
    display: grid
    grid-template-columns: 1fr
    column-gap: $spacing_s
    row-gap: $spacing_s
    justify-items: stretch
    align-items: center
    justify-content: center
    align-content: start
    grid-auto-flow: row

.shop__catalogue__element__actionbox__element
    width: 100%
    min-width: 2rem
    padding: $spacing_xs $spacing_s

.shop__catalogue__element__actionbox__element--half
    width: calc(50% - #{$spacing_s})

.shop__checkout-bar-container
    width: 100%
    position: fixed
    left: 0
    bottom: 0
    display: flex
    justify-content: center
    align-items: flex-start
    background-color: $color_primary
    color: $color_light
    z-index: 84

.shop__checkout-bar-container--wishlist
    background-color: $color_secondary

.shop__checkout-bar-wrapper
    min-width: $windowsize_min
    width: $windowsize
    max-width: $windowsize_max
    padding: $spacing_s $spacing_l
    display: flex
    flex-direction: column
    flex-wrap: wrap
    justify-content: space-between
    align-items: flex-start
    @include breakpoint(tablet)
        flex-wrap: nowrap
        flex-direction: row
    @include breakpoint(desktop)
        padding: $spacing_l $spacing_xl
        min-width: $windowsize_min_desktop
        width: $windowsize_wide_desktop
        max-width: $windowsize_wide_max_desktop

.shop__checkout-bar__summary
    width: 100%
    flex-grow: 1
    @include breakpoint(tablet)
        width: auto
        flex-grow: 1

.shop__checkout-bar__summary__element
    width: 100%

.shop__checkout-bar__actionbox
    width: 100%
    flex-grow: 1
    margin-top: $spacing_m
    display: flex
    justify-content: flex-end
    @include breakpoint(tablet)
        width: auto
        flex-grow: 0
        margin-top: 0

.shop__checkout-bar__actionbox__element

.form__content__element__cart
    width: 100%

.form__content__element__cart__element
    padding: $spacing_m 0
    border-top: $border-reg
    &:first-of-type
        border-top: none
        padding-top: 0
    &:last-of-type
        padding-bottom: 0

.shop__header
    width: 100%
    padding: $spacing_l 0 $spacing_l 0
    display: flex
    flex-direction: column
    justify-content: flex-start
    flex-wrap: wrap
    align-items: flex-start
    align-content: flex-start
    border-bottom: $border-subtle
    @include breakpoint(tablet)
        justify-content: center
        flex-wrap: nowrap
        align-items: flex-start
        align-content: flex-start

.shop__header__element
    width: 100%
    text-align: center
    margin-top: $spacing_m
    &:first-of-type
        margin-top: 0





// Rental list

.dashboard__navbar-container
    width: 100%
    margin-top: $spacing_l
    padding-top: $spacing_l
    margin-bottom: $spacing_l
    border-top: $border-subtle

.dashboard__navbar-wrapper
    width: 100%
    display: grid
    grid-template-columns: 1fr 1fr
    row-gap: $spacing_m
    column-gap: $spacing_m
    justify-items: stretch
    align-items: stretch
    justify-content: center
    align-content: start
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr 1fr
    @include breakpoint(laptop)
        grid-template-columns: 1fr 1fr 1fr 1fr
    @include breakpoint(desktop)
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr
    @include breakpoint(largedesktop)
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr

.dashboard__navbar__element





// Rental list

.rentallist__catalogue
    padding: 0 0 $spacing_l 0
    flex-grow: 1
    display: grid
    grid-template-columns: 1fr
    justify-items: stretch
    align-items: stretch
    justify-content: center
    align-content: start
    grid-auto-flow: row
    @include breakpoint(phablet)
        grid-template-columns: 1fr
    @include breakpoint(tablet)
        grid-template-columns: 1fr
        padding: $spacing_l 0 $spacing_l $spacing_m
    @include breakpoint(laptop)
        grid-template-columns: 1fr
    @include breakpoint(desktop)
        grid-template-columns: 1fr
    @include breakpoint(largedesktop)
        grid-template-columns: 1fr

.rentallist__catalogue__element
    padding: $spacing_s 0
    border-bottom: $border-subtle
    &:first-of-type
        padding-top: 0
    &:last-of-type
        border-bottom: none
        padding-bottom: 0

.rentallist__catalogue__element__toggle
    width: $lineheight_reg
    height: $lineheight_reg
    flex-grow: 0
    cursor: pointer
    position: absolute
    top: 0
    right: 0
    background-color: grey

.rentallist__catalogue__element__section
    width: 100%
    /* width: calc(100% - #{$lineheight_reg}) */
    /* padding-right: $spacing_m */
    display: grid
    grid-template-columns: 1fr
    row-gap: $spacing_xs
    column-gap: $spacing_m
    justify-items: stretch
    align-items: stretch
    justify-content: center
    align-content: start
    grid-auto-flow: row
    @include breakpoint(phablet)
        grid-template-columns: 1fr 1fr
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr
    @include breakpoint(laptop)
        grid-template-columns: 1fr 1fr
    @include breakpoint(desktop)
        grid-template-columns: 2fr 2fr 1fr 2fr 4fr

.rentallist__catalogue__element__section__element

.rentallist__catalogue__element__section__element

.rentallist__catalogue__element__section__buttonbox
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    row-gap: $spacing_xs
    column-gap: $spacing_xs
    grid-column: span 2
    justify-items: stretch
    align-items: stretch
    justify-content: center
    align-content: start
    grid-auto-flow: row
    @include breakpoint(desktop)
        grid-column: span 1

.rentallist__catalogue__element__section__buttonbox__button
    width: 100%
    text-align: center
    border-radius: $border_radius
    cursor: pointer





// Inventory

.inventory
    padding: 0 0 $spacing_l 0
    flex-grow: 1
    display: grid
    grid-template-columns: 1fr
    justify-items: stretch
    align-items: stretch
    justify-content: center
    align-content: start
    grid-auto-flow: row
    @include breakpoint(phablet)
        grid-template-columns: 1fr
    @include breakpoint(tablet)
        grid-template-columns: 1fr
        padding: $spacing_l 0 $spacing_l $spacing_m
    @include breakpoint(laptop)
        grid-template-columns: 1fr
    @include breakpoint(desktop)
        grid-template-columns: 1fr
    @include breakpoint(largedesktop)
        grid-template-columns: 1fr

.inventory__item
    display: flex
    justify-content: flex-start
    align-items: center
    padding: $spacing_s 0
    border-bottom: $border-subtle
    &:first-of-type
        padding-top: 0
    &:last-of-type
        border-bottom: none
        padding-bottom: 0

.inventory__item--unavailable

.inventory__item__picture
    width: 8rem
    flex-grow: 0
    height: 6rem
    border-radius: $border_radius
    margin-right: $spacing_s
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden

.inventory__item__picture--unavailable
    width: 100%
    height: 100%
    position: absolute
    background-color: rgba(255, 255, 255, 0.7)
    color: $color_dark
    display: flex
    justify-content: center
    align-items: center

.inventory__item__picture-inner
    width: 100%
    height: 100%
    padding: $spacing_s
    object-fit: contain
    object-position: 50% 50%
    overflow: hidden

.inventory__item__content
    width: 100%
    flex-grow: 1
    display: grid
    grid-template-columns: 1fr
    column-gap: $spacing_s
    row-gap: $spacing_s
    justify-items: stretch
    align-items: start
    justify-content: center
    align-content: start
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr
    @include breakpoint(laptop)
        grid-template-columns: 1fr 1fr

.inventory__item__content__element

.inventory__item__content__element--double
    grid-column: span 2

.inventory__item__content__element__title-container
    width: 100%
    display: flex
    justify-content: flex-start
    align-tems: center
    cursor: pointer

.inventory__item__content__element__title__dropdown
    width: $lineheight_reg
    height: $lineheight_reg
    flex-grow: 0
    fill: $color_dark
    transform: rotate(-90deg)
    transition: all $transitiontime

.inventory__item__content__element__title__dropdown--active
    transform: rotate(0)

.inventory__item__content__element__title__textbox
    width: auto
    flex-grow: 1
    font-weight: bold

.inventory__item__content__element__rentalbox
    width: 100%
    margin-left: calc(#{$lineheight_reg})
    margin-top: $spacing_s
    transition: all $transitiontime

.inventory__item__content__element__rentalbox--hidden
    display: none

.inventory__item__content__element__rental
    width: 100%
    border-bottom: $border-subtle
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: $spacing_s
    row-gap: $spacing_s
    justify-items: stretch
    align-items: start
    justify-content: start
    align-content: start
    grid-auto-flow: row
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr
    @include breakpoint(laptop)
        grid-template-columns: 1fr 1fr 1fr 1fr
    &:last-of-type
        border-bottom: none

.inventory__item__content__element__rental__element

.inventory__item__content__element__availability
    width: 100%
    margin-left: calc(#{$lineheight_reg})
    margin-top: $spacing_s
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: $spacing_s
    row-gap: $spacing_s
    justify-items: stretch
    align-items: start
    justify-content: start
    align-content: start
    grid-auto-flow: row
    transition: all $transitiontime
    @include breakpoint(tablet)
        grid-template-columns: 1fr 1fr 1fr 1fr
    @include breakpoint(desktop)
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr

.inventory__item__content__element__availability--hidden
    display: none

.inventory__item__content__element__availability__element
    width: 100%
    padding: $spacing_xs 0
    text-align: center
    border-radius: $border_radius
    color: $color_dark
    background-color: $color_primary_tint_3

.inventory__item__content__element__availability__element--unavailable
    background-color: $color_secondary