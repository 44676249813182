* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}

html, body {
  font-size: 16px;
}

body {
  color: #171333;
  font-family: arial;
  text-decoration: none;
  margin: auto;
  font-size: 1rem;
  line-height: 1.4rem;
  word-wrap: break-word;
  background-color: #FCFBFF;
}

a {
  text-decoration: none;
  color: #171333;
  -webkit-transition: color 500ms;
  transition: color 500ms;
}

a:hover {
  color: #231C4C;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.2rem 0 1.2rem 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  line-height: 1.8rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: #363b74;
}

p {
  display: block;
  -webkit-margin-before: 0rem;
  -webkit-margin-after: 0rem;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  list-style-type: square;
  margin: 0;
  padding: 0 0 0 1rem;
}

input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  outline: none;
  font-family: arial;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.4rem;
  word-wrap: break-word;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="file"]::-webkit-inner-spin-button,
input[type="file"]::-webkit-outer-spin-button,
input[type="file"]::-webkit-clear-button {
  display: none;
  visibility: hidden;
}

input,
textarea {
  all: unset;
  border: none;
}

button,
input,
optgroup,
select,
textarea,
html input[type="button"],
input[type="text"],
input[type="reset"],
input[type="submit"],
button[disabled],
html input[disabled],
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="checkbox"],
input[type="radio"],
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="search"],
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="date"],
input[type="date"]::-webkit-search-cancel-button,
input[type="date"]::-webkit-search-decoration {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: grey;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea {
  -webkit-box-shadow: 0 0 0 30px #FCFBFF inset !important;
}

.textbox {
  display: block;
  margin-bottom: 1.6rem;
  width: 100%;
}

.textbox:last-of-type {
  margin-bottom: 1.6rem;
}

@media (min-width: 768px) {
  .textbox {
    width: auto;
  }
}

.textbox--light {
  color: #FCFBFF;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

.text-grey {
  color: #656567;
}

.text-bold-extra {
  font-weight: 1000;
}

.text-color-primary {
  color: #363b74;
}

.text-color-secondary {
  color: #ef4f91;
}

.text-color-light {
  color: #FCFBFF;
}

.text-color-grey {
  color: #656567;
}

.text-title {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: bold;
}

.text-subtitle {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.text-normal {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: normal;
}

.text-justify {
  text-align: justify;
}

.link-color-light {
  color: #FCFBFF;
}

.link-color-light:hover {
  color: #FCFBFF;
}

.hidden {
  display: none !important;
}

.button {
  width: 100%;
  padding: 0.4rem 1.2rem 0.4rem 1.2rem;
  border-radius: 0.4rem;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.button:hover {
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
}

@media (min-width: 768px) {
  .button {
    min-width: 8rem;
    width: auto;
    display: inline-block;
  }
}

.button--subtitle {
  padding: 0.8rem 1.6rem;
}

@media (min-width: 768px) {
  .button--subtitle {
    min-width: 10rem;
  }
}

.button--margin {
  margin: 0 1.2rem 0 1.2rem;
}

.button-inactive {
  background-color: #E2E1E5 !important;
  border: 0.2rem solid #E2E1E5;
  color: #171333 !important;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) !important;
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) !important;
  cursor: not-allowed;
}

.button-inactive:hover {
  background-color: #E2E1E5 !important;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) !important;
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) !important;
}

.button-primary {
  background-color: #363b74;
  border: 0.2rem solid #363b74;
  color: #FCFBFF;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.button-primary:hover {
  background-color: #2b2f5c;
  border-color: #2b2f5c;
  color: #FCFBFF;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
}

.button-primary-light {
  background-color: #FCFBFF;
  border: 0.2rem solid #363b74;
  color: #363b74;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.button-primary-light:hover {
  background-color: #FCFBFF;
  border-color: #2b2f5c;
  color: #2b2f5c;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
}

.button-secondary {
  background-color: #ef4f91;
  border: 0.2rem solid #ef4f91;
  color: #FCFBFF;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.button-secondary:hover {
  background-color: #bf3f74;
  border-color: #bf3f74;
  color: #FCFBFF;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
}

.button-secondary-light {
  background-color: #FCFBFF;
  border: 0.2rem solid #ef4f91;
  color: #ef4f91;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.button-secondary-light:hover {
  background-color: #FCFBFF;
  border-color: #bf3f74;
  color: #bf3f74;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0);
}

.button-tertiary {
  background-color: #ecebef;
  border: 0.2rem solid #E2E1E5;
  color: #171333;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.button-tertiary:hover {
  background-color: #E2E1E5;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.header {
  width: 100%;
  background-color: #FCFBFF;
  /** box-shadow: $shadow_med **/
  background-color: #363b74;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 90;
}

.header-wrapper {
  min-width: 200px;
  width: 100%;
  max-width: 100%;
  padding: 0.8rem 1.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (min-width: 768px) {
  .header-wrapper {
    -ms-flex-wrap: no-wrap;
        flex-wrap: no-wrap;
  }
}

@media (min-width: 1280px) {
  .header-wrapper {
    padding: 0 3.2rem;
    min-width: 600px;
    width: 100%;
    max-width: 100%;
  }
}

.header__logo {
  width: 6rem;
  height: 2rem;
  margin: 0.8rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* fill: $color_primary */
  /* color: $color_light !important */
  background: url("media/images/logo_light.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.header__menu {
  width: 2rem;
  height: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
}

@media (min-width: 768px) {
  .header__menu {
    display: none;
  }
}

.header__menu__bar {
  width: 100%;
  height: 0.15rem;
  background-color: #FCFBFF;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.header__menu__bar-1--active {
  -webkit-transform: rotateZ(45deg) translateY(0.65rem);
  transform: rotateZ(45deg) translateY(0.65rem);
}

.header__menu__bar-2--active {
  display: none;
}

.header__menu__bar-3--active {
  -webkit-transform: rotateZ(-45deg) translateY(-0.65rem);
  transform: rotateZ(-45deg) translateY(-0.65rem);
}

.header__navbar {
  display: none;
  width: 100%;
}

@media (min-width: 768px) {
  .header__navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    width: auto;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
}

.header__navbar--active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.header__navbar__element {
  width: 100%;
  padding: 0.4rem 0 0.4rem 0;
  margin: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (min-width: 768px) {
  .header__navbar__element {
    width: auto;
    margin: 0 1.2rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .header__navbar__element:last-of-type {
    margin-right: 0;
  }
}

.header__navbar__link {
  width: 100%;
  height: 100%;
  color: #FCFBFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.header__navbar__link:hover {
  color: #aeb0c7;
}

@media (min-width: 768px) {
  .header__navbar__link {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.footer {
  width: 100%;
  background: url("media/patterns/flower_1.png");
  background-repeat: repeat;
  background-position: center center;
  background-color: #363b74;
  color: #FCFBFF;
  min-height: 5rem;
  z-index: 80;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.footer-wrapper {
  min-width: 200px;
  width: 100%;
  max-width: 100%;
  padding: 3.2rem 1.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media (min-width: 1280px) {
  .footer-wrapper {
    padding: 3.2rem 3.2rem;
    min-width: 600px;
    width: 80vw;
    max-width: 80vw;
  }
}

.footer__column {
  width: 100%;
  padding: 3.2rem 0 3.2rem 0;
}

@media (min-width: 768px) {
  .footer__column {
    width: calc(50% - 3.2rem);
  }
}

@media (min-width: 1280px) {
  .footer__column {
    width: calc(33.333% - 3.2rem);
    padding: 1.6rem 0 1.6rem 0;
  }
}

.footer__column__title {
  width: 100%;
  margin-top: 3.2rem;
}

.footer__column__title:first-of-type {
  margin-top: 0;
}

.footer__column__element {
  width: 100%;
  margin-bottom: 0.8rem;
}

.footer__column__element:last-of-type {
  margin-bottom: 0;
}

.footer-social-media-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1.4rem;
}

.footer-social-media-logo {
  width: 1.8rem;
  height: 1.8rem;
  margin: 0 0.4rem 0 0.4rem;
  fill: #FCFBFF;
  -webkit-transition: fill 500ms;
  transition: fill 500ms;
}

.footer-social-media-logo:hover {
  fill: url(#gradient-horizontal) #FCFBFF;
}

.content-container {
  width: 100%;
  position: relative;
}

.content-container--background-grey {
  background-color: #ecebef;
}

.content-wrapper {
  min-width: 200px;
  width: 100%;
  padding: 0 1.6rem;
}

@media (min-width: 1280px) {
  .content-wrapper {
    min-width: 600px;
    width: 100%;
    padding: 0 3.2rem;
  }
}

.content-wrapper--center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.content-wrapper--event {
  padding: 0;
}

.background-flowers {
  background: url("media/patterns/flower_1.png");
  background-repeat: repeat;
  background-position: center center;
  background-color: #363b74;
}

.input {
  border: 1px solid #171333;
  border-radius: 0.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 1.6rem;
}

.input:last-of-type {
  margin-bottom: 1.6rem;
}

.input--disabled {
  border-color: #CBCACE;
  color: #CBCACE;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #171333;
  background-color: rgba(20, 21, 21, 0.9);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 99;
}

.overlay__popup {
  min-width: 200px;
  width: 90vw;
  max-width: calc(100vw - 3.2rem);
  max-height: 90vh;
}

@media (min-width: 1024px) {
  .overlay__popup {
    min-width: 600px;
    width: 90vw;
    max-width: 1000px;
  }
}

.overlay__loading {
  color: #FCFBFF;
}

.form-container {
  background-color: #FCFBFF;
  border-radius: 0.4rem;
}

.form__header {
  width: 100%;
  border-radius: 0.4rem 0.4rem 0 0;
  background-color: #363b74;
  color: #FCFBFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form__header__title {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 0.8rem 0.8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: bold;
}

.form__header__close {
  width: 1.6rem;
  height: 1.6rem;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  margin: 0.8rem 0.8rem;
  fill: #FCFBFF;
  cursor: pointer;
}

.form__content {
  width: 100%;
  max-height: 80vh;
  padding: 1.2rem 1.2rem;
  border-radius: 0 0 0.4rem 0.4rem;
  overflow-y: auto;
}

.form__content--no-padding {
  padding: 0;
}

.form__content__element {
  margin-top: 1.6rem;
}

.form__content__element:first-of-type {
  margin-top: 0;
}

.form__content__element--double-columns {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 1.6rem;
          column-gap: 1.6rem;
  row-gap: 1.6rem;
  justify-items: stretch;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .form__content__element--double-columns {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

.form__content__optionbox {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 0.8rem;
          column-gap: 0.8rem;
  row-gap: 0.8rem;
  justify-items: stretch;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .form__content__optionbox {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  .form__content__optionbox--four {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .form__content__optionbox--four {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.form__content__optionbox__option {
  padding: 0.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 0.4rem;
  cursor: pointer;
}

.form__content__optionbox__option:hover {
  background-color: #ecebef;
}

.form__content__optionbox__option--selected {
  background-color: #ecebef;
}

.form__content__optionbox__option__illustration {
  width: 10rem;
  height: 10rem;
  border-radius: 0.4rem;
}

.form__content__optionbox__option__label {
  width: auto;
  margin-top: 0.8rem;
  font-weight: bold;
  color: #363b74;
}

.form__content__buttonbox {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (min-width: 768px) {
  .form__content__buttonbox {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
}

.form__content__buttonbox__button {
  width: 100%;
  margin-top: 1.2rem;
}

.form__content__buttonbox__button:first-of-type {
  margin-top: 0;
}

@media (min-width: 768px) {
  .form__content__buttonbox__button {
    width: calc(50% - 0.8rem);
    margin-top: 0;
  }
}

.form__input-container {
  width: 100%;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.form__input-container--half {
  width: calc(50% - 1.6rem);
}

.form__input__headline {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 0.4rem;
}

.form__input__headline__label {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.form__input__headline__infobox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form__input__headline__infobox__icon {
  width: 1rem;
  height: 1rem;
  fill: #363b74;
  border-radius: 0.5rem;
  margin-left: 0.8rem;
  cursor: pointer;
}

.form__input__headline__infobox__icon--required {
  fill: #ef4f91;
}

.form__input__switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  flexwrap: wrap;
  background-color: #E2E1E5;
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
}

.form__input__switch__button {
  width: calc(50% - 0.4rem);
  padding: 0.4rem 1.2rem;
  border-radius: 0.4rem;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.form__input__switch__button:hover {
  background-color: #ecebef;
}

.form__input__switch__button--selected {
  background-color: #FCFBFF;
  color: #363b74;
  font-weight: bold;
}

.form__input__switch__button--selected:hover {
  background-color: #FCFBFF;
  color: #363b74;
}

.form__input__text,
.form__input__textbox,
.form__input__number,
.form__input__price,
.form__input__date {
  width: 100%;
  border: 1px solid #CBCACE;
  cursor: pointer;
  border-radius: 0.4rem;
}

.form__input__price,
.form__input__date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.form__input__text__input,
.form__input__textbox__input,
.form__input__number__input,
.form__input__price__input {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0.8rem;
  border-radius: 0.4rem;
}

.form__input__dropdown {
  width: 100%;
  border: 1px solid #CBCACE;
  cursor: pointer;
  border-radius: 0.4rem;
}

.form__input__dropdown-multi-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.form__input__text--active,
.form__input__number--active,
.form__input__price--active,
.form__input__date--active,
.form__input__dropdown--active {
  border-color: #363b74;
}

.form__input__dropdown-multi__value {
  margin: 0.4rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
  background-color: #363b74;
  color: #FCFBFF;
}

.form__input__dropdown__input {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0.8rem;
}

.form__input__dropdown__input--multi {
  width: auto;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.form__input__dropdown-container {
  max-height: 16rem;
  width: 100%;
  overflow-y: auto;
  display: none;
}

.form__input__dropdown-container--active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.form__input__dropdown__element {
  width: 100%;
  padding: 0.4rem;
  border-top: 1px solid #E2E1E5;
}

.form__input__dropdown__element:hover {
  background-color: #363b74;
  color: #FCFBFF;
}

.form__input__dropdown__element:last-of-type {
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}

.form__input__dropdown__element--selected {
  background-color: #363b74;
  color: #FCFBFF;
}

.form__input__textbox {
  width: 100%;
  border: 1px solid #CBCACE;
  cursor: pointer;
  border-radius: 0.4rem;
}

.form__input__textbox__input {
  width: 100%;
  height: 100%;
  min-height: calc(4 * 1.4rem);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0.8rem;
  border-radius: 0.4rem;
}

.form__input__textbox--active {
  border-color: #363b74;
}

.form__input__date {
  width: 100%;
  border: 1px solid #CBCACE;
  cursor: pointer;
  border-radius: 0.4rem;
}

.form__input__date--active {
  border-color: #363b74;
}

.form__input__date__input {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0.8rem;
}

.form__input__time {
  width: 100%;
  border: 1px solid #CBCACE;
  cursor: pointer;
  border-radius: 0.4rem;
}

.form__input__time--active {
  border-color: #363b74;
}

.form__input__time__input {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0.8rem;
}

.form__input__checkbox {
  width: 100%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form__input__checkbox__checkbox {
  width: 1.6rem;
  height: 1.6rem;
  border: 0.15rem solid #CBCACE;
  border-radius: 0.4rem;
  margin-right: 0.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.form__input__checkbox__checkbox--active {
  border-color: #363b74;
}

.form__input__checkbox__checkbox-inner {
  width: calc(100% - 0.3rem);
  height: calc(100% - 0.3rem);
  background-color: transparent;
  border-radius: 0.2rem;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.form__input__checkbox__checkbox--active .form__input__checkbox__checkbox-inner {
  background-color: #363b74;
}

.form__input__checkbox:hover .form__input__checkbox__checkbox-inner {
  background-color: #aeb0c7;
}

.form__input__checkbox__label {
  min-width: 6rem;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.loading-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #171333;
  background-color: rgba(20, 21, 21, 0.9);
  color: #FCFBFF;
  font-size: 2rem;
  line-height: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 99;
}

.banner {
  min-width: 200px;
  width: 100%;
  padding: 1.2rem 1.6rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ef4f91;
  z-index: 100;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr auto;
      grid-template-columns: 1fr auto;
  -webkit-column-gap: 1.6rem;
          column-gap: 1.6rem;
  justify-items: stretch;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  -ms-flex-line-pack: stretch;
      align-content: stretch;
}

@media (min-width: 1280px) {
  .banner {
    min-width: 600px;
    width: 100%;
    padding: 1.2rem 3.2rem;
  }
}

.banner__notification {
  height: 5rem;
}

.banner__close {
  width: 1.6rem;
  height: 1.6rem;
  fill: #FCFBFF;
  cursor: pointer;
}

.hero {
  background-color: #363b74;
}

.hero-wrapper {
  min-height: calc(80vh - 2rem - 0.8rem - 0.8rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  justify-items: stretch;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 1.2rem;
          column-gap: 1.2rem;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  -ms-flex-line-pack: stretch;
      align-content: stretch;
}

@media (min-width: 1024px) {
  .hero-wrapper {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

.hero__illustration {
  width: 100%;
  height: 100%;
  display: none;
}

@media (min-width: 1024px) {
  .hero__illustration {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.hero__illustration__screen {
  width: 100%;
  height: auto;
  position: relative;
}

.hero__illustration__screen-outer {
  width: 100%;
  height: 100%;
  z-index: 2;
}

.hero__illustration__screen-inner {
  width: auto;
  height: auto;
  overflow: hidden;
  position: relative;
  position: absolute;
  top: 11%;
  bottom: 12%;
  left: 12%;
  right: 12%;
  z-index: 1;
  background-color: #FCFBFF;
}

.hero__illustration__screen__video {
  -o-object-fit: contain;
     object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.hero__content {
  width: 100%;
  height: 100%;
  padding: 3.2rem 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  row-gap: 1.2rem;
  justify-items: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  -ms-flex-line-pack: center;
      align-content: center;
}

.hero__content__title {
  width: auto;
}

.hero__content__subtitle {
  width: auto;
}

.hero__content__actionbutton {
  display: block;
}

.home__section {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 9.6rem;
  padding-bottom: 9.6rem;
}

.home__section:first-of-type {
  padding-top: 12.8rem;
}

.home__section:last-of-type {
  padding-bottom: 12.8rem;
}

.home__section__title {
  width: 100%;
  text-align: left;
  margin-bottom: 3.2rem;
}

@media (min-width: 768px) {
  .home__section__title {
    text-align: center;
  }
}

.home__section__contentbox {
  width: 100%;
}

.home__section__buttonbox {
  width: auto;
  height: auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 1.6rem;
          column-gap: 1.6rem;
  row-gap: 1.6rem;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .home__section__buttonbox {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

.home__features-container {
  width: 100%;
  height: 100%;
  padding: 1.6rem 3.2rem 3.2rem 3.2rem;
  border-radius: 1rem;
  background-color: #E2E1E5;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  position: relative;
}

.home__features__navbar {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 0;
          column-gap: 0;
  row-gap: 0.8rem;
  justify-items: stretch;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .home__features__navbar {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    border-bottom: 0.1rem solid #CBCACE;
  }
}

.home__features__navbar__element {
  padding: 0.8rem 0.8rem;
  cursor: pointer;
  font-weight: bold;
  color: #363b74;
  text-align: center;
  border-radius: 1rem;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.home__features__navbar__element:hover {
  background-color: #363b74;
  color: #FCFBFF;
}

@media (min-width: 768px) {
  .home__features__navbar__element {
    border-radius: 0;
    border-bottom: 0.3rem solid transparent;
  }
  .home__features__navbar__element:hover {
    background-color: transparent;
    color: #363b74;
    border-bottom: 0.3rem solid #aeb0c7;
  }
}

.home__features__navbar__element--selected {
  background-color: #363b74;
  color: #FCFBFF;
}

.home__features__navbar__element--selected:hover {
  background-color: #363b74;
  color: #FCFBFF;
}

@media (min-width: 768px) {
  .home__features__navbar__element--selected {
    background-color: transparent;
    color: #363b74;
    border-bottom: 0.3rem solid #363b74;
  }
  .home__features__navbar__element--selected:hover {
    background-color: transparent;
    color: #363b74;
    border-bottom: 0.3rem solid #363b74;
  }
}

.home__features__content-container {
  width: 100%;
  display: none;
}

.home__features__content-container--selected {
  margin-top: 1.6rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  row-gap: 1.6rem;
  justify-items: stretch;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .home__features__content-container--selected {
    -webkit-column-gap: 3.2rem;
            column-gap: 3.2rem;
    -ms-grid-columns: 2fr 1fr;
        grid-template-columns: 2fr 1fr;
  }
}

.home__features__content__screen {
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.home__features__content__screen__inner {
  width: 100%;
  height: auto;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
}

.home__features__content__description {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  row-gap: 1.2rem;
  grid-auto-flow: row;
}

.home__benefitlist {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 1.6rem;
          column-gap: 1.6rem;
  row-gap: 1.6rem;
  justify-items: stretch;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .home__benefitlist {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

.home__benefit {
  width: 100%;
  height: 100%;
  padding: 3.2rem 3.2rem;
  border-radius: 1rem;
  background-color: #E2E1E5;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  position: relative;
}

@media (min-width: 768px) {
  .home__benefit {
    padding: 3.2rem 1.6rem;
  }
}

@media (min-width: 1024px) {
  .home__benefit {
    padding: 1.6rem 3.2rem 3.2rem 3.2rem;
  }
}

.home__benefit__label {
  width: 100%;
  color: #363b74;
  margin-top: 1.2rem;
}

.home__benefit__description {
  width: 100%;
  margin-top: 0.8rem;
}

.home__benefit__illustration {
  width: 100%;
  height: auto;
  min-height: 6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.home__benefit__illustration__inner {
  width: 100%;
  height: auto;
}

.home__working {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 0;
          column-gap: 0;
  row-gap: 0.8rem;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  -ms-flex-line-pack: stretch;
      align-content: stretch;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .home__working {
    -ms-grid-columns: 1fr 1.6rem 1fr 1.6rem 1fr;
        grid-template-columns: 1fr 1.6rem 1fr 1.6rem 1fr;
    -webkit-column-gap: 1.2rem;
            column-gap: 1.2rem;
    row-gap: 3.2rem;
  }
}

.home__working__step {
  width: 100%;
  height: 100%;
  padding: 3.2rem;
  border-radius: 1rem;
  background-color: #E2E1E5;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  background-color: #E2E1E5;
}

@media (min-width: 768px) {
  .home__working__step {
    padding: 3.2rem 1.6rem;
  }
}

@media (min-width: 1024px) {
  .home__working__step {
    padding: 3.2rem;
  }
}

.home__working__step__illustration-wrapper {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home__working__step__illustration {
  width: 100%;
  max-width: 12.8rem;
  height: auto;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .home__working__step__illustration {
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 1024px) {
  .home__working__step__illustration {
    max-width: 9.6rem;
    height: auto;
  }
}

.home__working__step__illustration__shadow {
  width: 100%;
  max-width: 12.8rem;
  height: 100%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) inset;
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) inset;
  position: absolute;
}

@media (min-width: 768px) {
  .home__working__step__illustration__shadow {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .home__working__step__illustration__shadow {
    max-width: 9.6rem;
  }
}

.home__working__step__label {
  width: 100%;
  margin-top: 1.6rem;
}

.home__working__step__description {
  width: 100%;
  margin-top: 0.8rem;
}

.home__working__separator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home__working__separator__arrow {
  width: 1.2rem;
  height: 3.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  fill: #363b74;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

@media (min-width: 768px) {
  .home__working__separator__arrow {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

.home__testimonials-container {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3rem 1fr 3rem;
      grid-template-columns: 3rem 1fr 3rem;
  justify-items: stretch;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  grid-auto-flow: row;
}

.home__testimonials__button {
  width: 100%;
  height: 3.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  fill: #aeb0c7;
  cursor: pointer;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.home__testimonials__button:hover {
  fill: #363b74;
}

.home__testimonials__button__previous {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.home__testimonials__button__next {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.home__testimonials__testimonial {
  margin: 0 1.6rem;
  padding: 1.6rem 1.6rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 1.6rem;
          column-gap: 1.6rem;
  row-gap: 1.6rem;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  grid-auto-flow: row;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
  border-radius: 1rem;
  background-color: #ecebef;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

@media (min-width: 768px) {
  .home__testimonials__testimonial {
    margin: 0 1.6rem;
    padding: 3.2rem 3.2rem;
    -ms-grid-columns: 1fr 2fr;
        grid-template-columns: 1fr 2fr;
  }
}

.home__testimonials__testimonial--hidden {
  display: none;
}

.home__testimonials__testimonial__picture-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.home__testimonials__testimonial__picture {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) inset;
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) inset;
}

.home__testimonials__testimonial__picture--jamar {
  background: url("media/images/home/testimonials_jamar.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.home__testimonials__testimonial__picture--oskar {
  background: url("media/images/home/testimonials_oskar.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.home__testimonials__testimonial__picture--janice {
  background: url("media/images/home/testimonials_janice.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.home__testimonials__testimonial__name {
  width: 100%;
  margin-top: 1.2rem;
  color: #656567;
}

.home__testimonials__testimonial__title {
  width: 100%;
  margin-top: 0.4rem;
}

.home__testimonials__testimonial__icon {
  width: 1.6rem;
  height: 1.6rem;
  background: url("media/images/home/testimonials_quote.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.home__testimonials__testimonial__quote {
  margin-top: 1.2rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  row-gap: 0.8rem;
  grid-auto-flow: row;
  font-style: italic;
}

.home__references-container {
  min-width: 200px;
  width: 100%;
  padding: 1.6rem 1.6rem;
  background-color: #363b74;
}

@media (min-width: 1280px) {
  .home__references-container {
    min-width: 600px;
    width: 100%;
    padding: 1.6rem 3.2rem;
  }
}

.home__references-wrapper {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 10rem;
      grid-template-columns: 10rem;
  -webkit-column-gap: 6.4rem;
          column-gap: 6.4rem;
  row-gap: 1.6rem;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .home__references-wrapper {
    -ms-grid-columns: 8rem 8rem;
        grid-template-columns: 8rem 8rem;
  }
}

@media (min-width: 1024px) {
  .home__references-wrapper {
    grid-auto-flow: column;
    grid-auto-columns: 8rem;
  }
}

.home__references__reference {
  width: 100%;
  height: auto;
}

.contact__title {
  padding-top: 6.4rem;
  text-align: center;
}

.contact__section {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}

.contact__section:first-of-type {
  padding-top: 3.2rem;
}

.contact__section:last-of-type {
  padding-bottom: 6.4rem;
}

@media (min-width: 1024px) {
  .contact__section {
    max-width: 50rem;
  }
}

.home__working__illustration {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.home__working__illustration--left {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.home__working__illustration--right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.home__working__illustration-inner {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) inset;
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3) inset;
}

.home__working__illustration-inner--guest {
  background: url("media/images/home_guests_1.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.home__working__illustration-inner--hotel {
  background: url("media/images/home_hotels_1.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.home__working__textbox {
  display: inline-block;
}

.home__working__textbox__content {
  width: 100%;
  margin-top: 1.6rem;
}

.home__working__textbox__paragraph {
  margin-top: 0.8rem;
}

.home__working__textbox__paragraph:first-of-type {
  margin-top: 0;
}

.home__working__icon {
  background-color: grey;
}

.form__content__optionbox__option__illustration--business {
  background: url("media/images/home_mode_business.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--leisure {
  background: url("media/images/home_mode_leisure.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--business_essentials {
  background: url("media/images/home_cat_business_essentials.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--electronics {
  background: url("media/images/home_cat_electronics.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--personal_care {
  background: url("media/images/home_cat_personal_care.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--hair_care {
  background: url("media/images/home_cat_hair_care.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--clothing {
  background: url("media/images/home_cat_clothing.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--fitness {
  background: url("media/images/home_cat_fitness.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--entertainment {
  background: url("media/images/home_cat_entertainment.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--other {
  background: url("media/images/home_cat_other.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--extension_monitor {
  background: url("media/images/home_item_extension_monitor.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--mouse {
  background: url("media/images/home_item_mouse.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--keyboard {
  background: url("media/images/home_item_keyboard.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--projector {
  background: url("media/images/home_item_projector.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--printer {
  background: url("media/images/home_item_printer.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--charger {
  background: url("media/images/home_item_charger.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--headphones {
  background: url("media/images/home_item_headphones.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--international_adapters {
  background: url("media/images/home_item_international_adapters.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--portable_wifi {
  background: url("media/images/home_item_portable_wifi.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--skin_care {
  background: url("media/images/home_item_skin_care.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--toiletries {
  background: url("media/images/home_item_toiletries.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--premium_hairdryer {
  background: url("media/images/home_item_premium_hairdryer.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--straightener {
  background: url("media/images/home_item_straightener.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--curler {
  background: url("media/images/home_item_curler.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--dresses {
  background: url("media/images/home_item_dresses.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--accessories {
  background: url("media/images/home_item_accessories.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--handbags {
  background: url("media/images/home_item_handbags.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--suits {
  background: url("media/images/home_item_suits.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--yoga_mat {
  background: url("media/images/home_item_yoga_mat.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--weights {
  background: url("media/images/home_item_weights.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.form__content__optionbox__option__illustration--gaming_consoles {
  background: url("media/images/home_item_gaming_consoles.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.hotel-header-container {
  width: 100%;
  margin-top: 1.6rem;
}

.hotel-header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-line-pack: start;
      align-content: flex-start;
}

@media (min-width: 768px) {
  .hotel-header-wrapper {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
  }
}

.hotel-header__logo {
  width: 12rem;
  height: 4rem;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (min-width: 768px) {
  .hotel-header__logo {
    height: 6rem;
  }
}

.hotel-header__logo-inner {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  max-height: 100%;
}

.hotel-header__detailbox {
  min-width: 12rem;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-top: 0.8rem;
}

@media (min-width: 768px) {
  .hotel-header__detailbox {
    margin-top: 0;
  }
}

.hotel-header__detailbox__element {
  margin-top: 0.2rem;
  color: #656567;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.hotel-header__detailbox__element:first-of-type {
  margin-top: 0;
}

.hotel-header__detailbox__element a {
  color: #656567;
}

.hotel-header__detailbox__element a:hover {
  color: #171333;
}

.shop-container,
.rentallist-container {
  width: 100%;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  border-top: 1px solid #E2E1E5;
}

.shop-wrapper,
.rentallist-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -ms-flex-line-pack: start;
      align-content: flex-start;
}

@media (min-width: 768px) {
  .shop-wrapper,
  .rentallist-wrapper {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }
}

.shop__categories,
.rentallist__categories {
  width: 100%;
  padding: 1.6rem 0 1.6rem 0;
}

@media (min-width: 768px) {
  .shop__categories,
  .rentallist__categories {
    width: auto;
    padding: 1.6rem 1.2rem 1.6rem 0;
    border-right: 1px solid #E2E1E5;
  }
}

.shop__categories__section,
.rentallist__categories__section {
  width: 100%;
  margin-top: 1.2rem;
}

.shop__categories__section:first-of-type,
.rentallist__categories__section:first-of-type {
  margin-top: 0;
}

@media (min-width: 768px) {
  .shop__categories__section,
  .rentallist__categories__section {
    margin-top: 1.6rem;
  }
}

.shop__categories__element,
.rentallist__categories__element {
  min-width: 12rem;
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  margin-top: 0.4rem;
  cursor: pointer;
  background-color: #E2E1E5;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.shop__categories__element:first-of-type,
.rentallist__categories__element:first-of-type {
  margin-top: 0;
}

.shop__categories__element:hover,
.rentallist__categories__element:hover {
  background-color: #CBCACE;
}

@media (min-width: 768px) {
  .shop__categories__element,
  .rentallist__categories__element {
    margin-top: 0.4rem;
  }
}

.shop__categories__element--selected,
.rentallist__categories__element--selected {
  color: #FCFBFF;
  background-color: #363b74;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.shop__categories__element--selected:hover,
.rentallist__categories__element--selected:hover {
  background-color: #2b2f5c;
}

.shop__catalogue {
  padding: 0 0 1.6rem 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 1.2rem;
          column-gap: 1.2rem;
  row-gap: 1.6rem;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 600px) {
  .shop__catalogue {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  .shop__catalogue {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    padding: 1.6rem 0 1.6rem 1.2rem;
    -webkit-column-gap: 1.2rem;
            column-gap: 1.2rem;
    row-gap: 1.2rem;
  }
}

@media (min-width: 1024px) {
  .shop__catalogue {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1280px) {
  .shop__catalogue {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1600px) {
  .shop__catalogue {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.shop__catalogue__element {
  padding: 1.2rem;
  border: 0.2rem solid #FCFBFF;
  border-radius: 0.4rem;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.shop__catalogue__element:hover {
  -webkit-box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
          box-shadow: 0 0 20px -10px rgba(17, 17, 17, 0.3);
}

@media (min-width: 768px) {
  .shop__catalogue__element {
    padding: 1.2rem;
  }
}

.shop__catalogue__element--selected {
  border-radius: 0.4rem;
  border-color: #363b74;
}

.shop__catalogue__element--selected-wishlist {
  border-radius: 0.4rem;
  border-color: #ef4f91;
}

.shop__catalogue__element__picture {
  width: 100%;
  min-height: 10rem;
  max-height: 12rem;
  border-radius: 0.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}

@media (min-width: 768px) {
  .shop__catalogue__element__picture {
    max-height: 20rem;
  }
}

.shop__catalogue__element__picture-unavailable {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #FCFBFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.shop__catalogue__element__picture-inner {
  width: 100%;
  padding: 0.8rem;
  height: 12rem;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  overflow: hidden;
}

.shop__catalogue__element__label {
  width: 100%;
  margin-top: 0.4rem;
}

.shop__catalogue__element__details {
  width: 100%;
  color: #656567;
}

.shop__catalogue__element__actionbox {
  width: 100%;
  margin-top: 0.4rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 0.8rem;
          column-gap: 0.8rem;
  row-gap: 0.8rem;
  justify-items: stretch;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

.shop__catalogue__element__actionbox__element {
  width: 100%;
  min-width: 2rem;
  padding: 0.4rem 0.8rem;
}

.shop__catalogue__element__actionbox__element--half {
  width: calc(50% - 0.8rem);
}

.shop__checkout-bar-container {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background-color: #363b74;
  color: #FCFBFF;
  z-index: 84;
}

.shop__checkout-bar-container--wishlist {
  background-color: #ef4f91;
}

.shop__checkout-bar-wrapper {
  min-width: 200px;
  width: 100%;
  max-width: 100%;
  padding: 0.8rem 1.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

@media (min-width: 768px) {
  .shop__checkout-bar-wrapper {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media (min-width: 1280px) {
  .shop__checkout-bar-wrapper {
    padding: 1.6rem 3.2rem;
    min-width: 600px;
    width: 100%;
    max-width: 100%;
  }
}

.shop__checkout-bar__summary {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

@media (min-width: 768px) {
  .shop__checkout-bar__summary {
    width: auto;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
}

.shop__checkout-bar__summary__element {
  width: 100%;
}

.shop__checkout-bar__actionbox {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-top: 1.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

@media (min-width: 768px) {
  .shop__checkout-bar__actionbox {
    width: auto;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    margin-top: 0;
  }
}

.form__content__element__cart {
  width: 100%;
}

.form__content__element__cart__element {
  padding: 1.2rem 0;
  border-top: 1px solid #CBCACE;
}

.form__content__element__cart__element:first-of-type {
  border-top: none;
  padding-top: 0;
}

.form__content__element__cart__element:last-of-type {
  padding-bottom: 0;
}

.shop__header {
  width: 100%;
  padding: 1.6rem 0 1.6rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  border-bottom: 1px solid #E2E1E5;
}

@media (min-width: 768px) {
  .shop__header {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }
}

.shop__header__element {
  width: 100%;
  text-align: center;
  margin-top: 1.2rem;
}

.shop__header__element:first-of-type {
  margin-top: 0;
}

.dashboard__navbar-container {
  width: 100%;
  margin-top: 1.6rem;
  padding-top: 1.6rem;
  margin-bottom: 1.6rem;
  border-top: 1px solid #E2E1E5;
}

.dashboard__navbar-wrapper {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  row-gap: 1.2rem;
  -webkit-column-gap: 1.2rem;
          column-gap: 1.2rem;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .dashboard__navbar-wrapper {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .dashboard__navbar-wrapper {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1280px) {
  .dashboard__navbar-wrapper {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1600px) {
  .dashboard__navbar-wrapper {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.rentallist__catalogue {
  padding: 0 0 1.6rem 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 600px) {
  .rentallist__catalogue {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) {
  .rentallist__catalogue {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    padding: 1.6rem 0 1.6rem 1.2rem;
  }
}

@media (min-width: 1024px) {
  .rentallist__catalogue {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media (min-width: 1280px) {
  .rentallist__catalogue {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media (min-width: 1600px) {
  .rentallist__catalogue {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

.rentallist__catalogue__element {
  padding: 0.8rem 0;
  border-bottom: 1px solid #E2E1E5;
}

.rentallist__catalogue__element:first-of-type {
  padding-top: 0;
}

.rentallist__catalogue__element:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

.rentallist__catalogue__element__toggle {
  width: 1.4rem;
  height: 1.4rem;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  background-color: grey;
}

.rentallist__catalogue__element__section {
  width: 100%;
  /* width: calc(100% - 1.4rem) */
  /* padding-right: $spacing_m */
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  row-gap: 0.4rem;
  -webkit-column-gap: 1.2rem;
          column-gap: 1.2rem;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 600px) {
  .rentallist__catalogue__element__section {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  .rentallist__catalogue__element__section {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .rentallist__catalogue__element__section {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1280px) {
  .rentallist__catalogue__element__section {
    -ms-grid-columns: 2fr 2fr 1fr 2fr 4fr;
        grid-template-columns: 2fr 2fr 1fr 2fr 4fr;
  }
}

.rentallist__catalogue__element__section__buttonbox {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  row-gap: 0.4rem;
  -webkit-column-gap: 0.4rem;
          column-gap: 0.4rem;
  grid-column: span 2;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 1280px) {
  .rentallist__catalogue__element__section__buttonbox {
    grid-column: span 1;
  }
}

.rentallist__catalogue__element__section__buttonbox__button {
  width: 100%;
  text-align: center;
  border-radius: 0.4rem;
  cursor: pointer;
}

.inventory {
  padding: 0 0 1.6rem 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  justify-items: stretch;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 600px) {
  .inventory {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) {
  .inventory {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    padding: 1.6rem 0 1.6rem 1.2rem;
  }
}

@media (min-width: 1024px) {
  .inventory {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media (min-width: 1280px) {
  .inventory {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media (min-width: 1600px) {
  .inventory {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

.inventory__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.8rem 0;
  border-bottom: 1px solid #E2E1E5;
}

.inventory__item:first-of-type {
  padding-top: 0;
}

.inventory__item:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

.inventory__item__picture {
  width: 8rem;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  height: 6rem;
  border-radius: 0.4rem;
  margin-right: 0.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}

.inventory__item__picture--unavailable {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  color: #171333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.inventory__item__picture-inner {
  width: 100%;
  height: 100%;
  padding: 0.8rem;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  overflow: hidden;
}

.inventory__item__content {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 0.8rem;
          column-gap: 0.8rem;
  row-gap: 0.8rem;
  justify-items: stretch;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .inventory__item__content {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .inventory__item__content {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

.inventory__item__content__element--double {
  grid-column: span 2;
}

.inventory__item__content__element__title-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  align-tems: center;
  cursor: pointer;
}

.inventory__item__content__element__title__dropdown {
  width: 1.4rem;
  height: 1.4rem;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  fill: #171333;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.inventory__item__content__element__title__dropdown--active {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.inventory__item__content__element__title__textbox {
  width: auto;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font-weight: bold;
}

.inventory__item__content__element__rentalbox {
  width: 100%;
  margin-left: calc(1.4rem);
  margin-top: 0.8rem;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.inventory__item__content__element__rentalbox--hidden {
  display: none;
}

.inventory__item__content__element__rental {
  width: 100%;
  border-bottom: 1px solid #E2E1E5;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 0.8rem;
          column-gap: 0.8rem;
  row-gap: 0.8rem;
  justify-items: stretch;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
}

@media (min-width: 768px) {
  .inventory__item__content__element__rental {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .inventory__item__content__element__rental {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.inventory__item__content__element__rental:last-of-type {
  border-bottom: none;
}

.inventory__item__content__element__availability {
  width: 100%;
  margin-left: calc(1.4rem);
  margin-top: 0.8rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 0.8rem;
          column-gap: 0.8rem;
  row-gap: 0.8rem;
  justify-items: stretch;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -ms-flex-line-pack: start;
      align-content: start;
  grid-auto-flow: row;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

@media (min-width: 768px) {
  .inventory__item__content__element__availability {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1280px) {
  .inventory__item__content__element__availability {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.inventory__item__content__element__availability--hidden {
  display: none;
}

.inventory__item__content__element__availability__element {
  width: 100%;
  padding: 0.4rem 0;
  text-align: center;
  border-radius: 0.4rem;
  color: #171333;
  background-color: #aeb0c7;
}

.inventory__item__content__element__availability__element--unavailable {
  background-color: #ef4f91;
}
