// Config

// Global colors
$color_primary: #363b74
$color_secondary: #ef4f91
$color_tertiary: #c79dd7
$color_primary_shade_1: #2b2f5c
$color_primary_shade_2: #202345
$color_primary_shade_3: #15172e
$color_primary_tint_1: #5e628f
$color_primary_tint_2: #8689ab
$color_primary_tint_3: #aeb0c7
$color_secondary_shade_1: #bf3f74
$color_secondary_tint_1: #f272a7
$color_tertiary_shade_1: #9f7dac
$color_tertiary_tint_1: #d2b0df
$color_light: #FCFBFF
$color_grey_extralight: #ecebef
$color_grey_light: #E2E1E5
$color_grey: #CBCACE
$color_grey_dark: #656567
$color_dark: #171333
$color_dark_secondary: #231C4C
$color_dark_r: 20
$color_dark_g: 21
$color_dark_b: 21

// Universal config
$bp-tablet: 768px
$bp-desktop: 1280px

$windowsize: 100%
$windowsize_desktop: 80vw
$windowsize_min: 200px
$windowsize_min_desktop: 600px
$windowsize_max: 100%
$windowsize_max_desktop: 80vw

$content_width_max: 1200px

$windowsize_wide_desktop: 100%
$windowsize_wide_max_desktop: 100%

$windowsize_slim_max_desktop: 1000px

$sidebarsize: 10vw
$sidebarsize-min: 12rem

$spacing_xxs: 0.2rem
$spacing_xs: 0.4rem
$spacing_s: 0.8rem
$spacing_m: 1.2rem
$spacing_l: 1.6rem
$spacing_ll: 2.4rem
$spacing_xl: 3.2rem
$spacing_xxl: 6.4rem
$spacing_xxxl: 9.6rem
$spacing_xxxxl: 12.8rem

$border_radius: 0.4rem
$border_radius_l: 1rem
$border_radius_s: 0.2rem

$shadow_s: 2px 2px 4px -2px rgba(17,17,17,0.6)
$shadow_med: 0 0 20px -10px rgba(17,17,17,0.3)
$shadow_med_none: 0 0 20px -10px rgba(17,17,17,0)
$shadow_strong: 2px 2px 10px -2px rgba(17,17,17,0.6)

$fontsize_s: 0.8rem
$fontsize_reg: 1.0rem
$fontsize_l: 1.2rem
$fontsize_xl: 1.4rem
$fontsize_xxl: 1.8rem

$lineheight_s: 1.0rem
$lineheight_reg: 1.4rem
$lineheight_l: 1.6rem
$lineheight_xl: 1.8rem
$lineheight_xxl: 2.4rem

$border-subtle: 1px solid $color_grey_light
$border-reg: 1px solid $color_grey
$border-bold: 2px solid $color_primary

$transitiontime: 500ms


// Mixins

@mixin breakpoint($point)
  @if $point == largedesktop
    @media (min-width: 1600px)
      @content
  @if $point == desktop
    @media (min-width: 1280px)
      @content
  @else if $point == laptop
    @media (min-width: 1024px)
      @content
  @else if $point == tablet
    @media (min-width: 768px)
      @content
  @else if $point == phablet
    @media (min-width: 600px)
      @content
  @else if $point == mobileonly
    @media (max-width: 600px)
      @content


=transform($property)
  -webkit-transform: $property
  -ms-transform: $property
  transform: $property
//+transform(rotate(30deg))